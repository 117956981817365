module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-styled-components@6.14.0_babel-plugin-styled-components@2.1.4_gatsby@5.14.1_rea_drk5yv2cm6fxwfdp526ii3k6ke/node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":false,"fileName":false,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@5.14.1_babel-eslint@10.1.0_react-dom@18.3.1_react@18.3.1_typescript@5.7.3/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
